import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-multi-lang";
import {
    FullContrastBlueTextField, FullContrastPurpleTextField,
    Modules,
    WhiteBlueSwitch
} from "../../../../../../Utils/Global";
import {useUserData} from "../../../../../../hooks/userHook";
import {Button, Card, Col, ListGroup, Modal, Row} from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import _ from "lodash";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {useNavigate} from "react-router-dom";
import SideForm from "../../../../../Core/Modules/Views-Tables-Lists/SideForm";
import HttpApi from "../../../../../../httpApi";
import {
    createSuccessfullyDeletedNotification,
    createSuccessNotification,
    notifyError, notifySuccess
} from "../../../../../../Utils/Notification";
import {Colors} from "../../../../../../Utils/Colors";
import LoadingView from "../../../../../Core/Layouts/LoadingView";
import IconButton from "@mui/material/IconButton/IconButton";
import EbloomTooltip from "../../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {Divider, FormControl} from "@mui/material";
import DeleteModal from "../../../../../Core/Modules/Modal/DeleteModal";
import ConfirmModal from "../../../../../Core/Modules/Modal/ConfirmModal";
import EditIcon from "@mui/icons-material/CreateOutlined";

const AutoPlanner = (props) => {
    const {autoPlannerData,companyToEdit,playlists,frequencies,teams,selectedEntity,fetchAutoPlanners,shouldShowDelete,isSingle,handleToday, fetchNumberFutureQuestions, usedPlaylists,isStarter} = props

    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [autoplanner, setAutoplanner] = useState(autoPlannerData);
    const [showModalStartPlanner, setShowModalStartPlanner] = useState(false);
    const [loadingPlannerNow, setLoadingPlannerNow] = useState(false);
    const [loadingPlannerNext, setLoadingPlannerNext] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([-1]);
    const [showFrequency, setShowFrequency] = useState(false);
    const [frequency, setFrequency] = useState(null);
    const [openStartModal, setOpenStartModal] = useState(false);
    const [autoPlannerAccessDenied, setAutoPlannerAccessDenied] = useState(false);
    const [modalFrequencyEmpty, setModalFrequencyEmpty] = useState(false);

    const t = useTranslation();
    const user = useUserData();
    const navigate = useNavigate();


    const isFree = user.module === Modules.FREE[0];
    const week = ["monday","tuesday","wednesday","thursday","friday"];
    const recurrencies = [{value:1, name:"core.everyWeek"}, {value:2, name:"core.everyTwoWeeks"}, {value:3, name:"core.everyThreeWeeks"}, {value:4, name:"core.everyFourWeeks"}];


    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(!open){
            setSelectedTeams(autoPlannerData.auto_planner_teams.length > 0 ? autoPlannerData.auto_planner_teams.map(el => el.team.id) : [-1])
            setAutoplanner(autoPlannerData)
        }
        setSideFormOpen(open);
    }

    const handleOpenFrequency = (id) => {
        setFrequency(frequencies.find(el => el.id === id) || null);
        setShowFrequency(true);
    }

    const handleCloseFrequency = () => {
        setShowFrequency(false);
        setFrequency(null);

    }

    const handleCloseModal = () => {
        setShowModalStartPlanner(false);
    }

    const handleCloseModalStart = () => {
        setOpenStartModal(false);
    }

    const handleSaveChanges = (infos = null) => {
        setShowModalStartPlanner(false);
        const data = {
            id : autoplanner.id,
            id_playlist : autoplanner.id_playlist,
            id_frequency : autoplanner.id_frequency,
            active : false,
            auto_planner_teams : selectedTeams.length === 1 && selectedTeams[0] === -1 ? [] : selectedTeams
        }
        let url = "/frequencies/autoPlanner/update"
        if(!!selectedEntity){
            url = "/frequencies/autoPlanner/update/"+selectedEntity
        }

        HttpApi.put(url,!!infos ? infos : data).then((response) => {
            if(response && response.data){
                if(infos === null) notifySuccess("Auto Planner updated successfully")
                setSideFormOpen(false)
                fetchAutoPlanners();
            }
        }).catch(error => {
            notifyError(error);
        })

    }

    const handleDeleteAutoPlanner = (id) => {
        let url = "/frequencies/autoPlanner/delete"
        if (!!selectedEntity) {
            url += "/" + selectedEntity
        }
        HttpApi.postV2(url, {id: id}).then(response => {
            if(response && response.data){
                createSuccessfullyDeletedNotification("auto planner");
                setSideFormOpen(false)
                fetchAutoPlanners();
            }

        }).catch(error => {
            notifyError(error);
        })
    }
    const startAutoPlanner = (type) => event => {
        event.preventDefault();
        if(!loadingPlannerNow && !loadingPlannerNext){
            if(type === "now"){
                setLoadingPlannerNow(true);
            }else{
                setLoadingPlannerNext(true);
            }
            let url = "/frequencies/autoPlanner/updateActiveState"
            if(!!selectedEntity){
                url = "/frequencies/autoPlanner/updateActiveState/"+selectedEntity
            }
            const data = {id_auto_planner:autoplanner.id,active:true,timing:type}
            HttpApi.put(url, data).then((response) => {
                if(response) {
                    if (response.data) {
                        createSuccessNotification(_.upperFirst(t("admin.autoPlanner.name")), _.upperFirst(t("admin.autoPlanner.notifActivated")));
                        setAutoplanner({...autoplanner, active: true})
                        handleToday();
                        fetchNumberFutureQuestions();
                        fetchAutoPlanners()
                    } else {
                        setAutoplanner({...autoplanner, active: false})
                        setAutoPlannerAccessDenied(true);
                    }
                }else{
                    setAutoplanner({...autoplanner, active: false})
                }
            }).catch((error) => {
                notifyError(error);
            }).finally(() => {
                setOpenStartModal(false)
                setLoadingPlannerNow(false);
                setLoadingPlannerNext(false);
            })
        }
    }

    const handleChangeActive = (event) => {
        let value = event.target.checked;
        if(value){
            const totalCbFrequency = week.reduce((previousValue, currentValue) => previousValue + autoPlannerData.frequency[currentValue],0)
            if(totalCbFrequency === 0){
                setModalFrequencyEmpty(true)
            }else{
                setOpenStartModal(true);
            }
        }else{
            let url = "/frequencies/autoPlanner/updateActiveState"
            if(!!selectedEntity){
                url = "/frequencies/autoPlanner/updateActiveState/"+selectedEntity
            }
            const data = {id_auto_planner:autoplanner.id,active:false,timing:'now'}
            HttpApi.put(url,data).then(() => {
                setAutoplanner({...autoplanner, active:value})
                fetchAutoPlanners()
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    const handleChange = (event) => {
        let value = event.target.value
        let name = event.target.name
        if(isSingle){
            let autoplannerData = autoplanner
            let teamsSelected = selectedTeams
            if(name === "teams") {
                teamsSelected = []
                if (value.length > 0) {
                    if (value.includes(-1) && selectedTeams.includes(-1)) {
                        setSelectedTeams(value.filter(el => el !== -1))
                        teamsSelected = value.filter(el => el !== -1)
                    } else if (value.includes(-1) && !selectedTeams.includes(-1)) {
                        setSelectedTeams([-1])
                        teamsSelected = []
                    } else {
                        setSelectedTeams(value.filter(el => el !== -1))
                        teamsSelected = value.filter(el => el !== -1)
                    }
                }
                autoplannerData.auto_planner_teams = teamsSelected
            }else {
                if(name === "id_frequency"){
                    const frequency = frequencies.find(el => el.id === value)
                    if(frequency){
                        const nbCB = week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue],0)
                        const playlist = playlists.find(el => el.id === autoplanner.id_playlist)
                        if(playlist && playlist.nbCB < nbCB ){
                            autoplannerData[name]= value
                            autoplannerData.id_playlist = 0
                        }else {
                            autoplannerData[name]= value
                        }
                    }
                }else if(name === "id_playlist"){
                    autoplannerData[name] = value
                }

            }
            autoplannerData.auto_planner_teams = teamsSelected.length === 1 && teamsSelected[0] === -1 ? [] : teamsSelected
            handleSaveChanges(autoplannerData)

        }else{
            if(name === "teams"){
                if(value.length > 0){
                    if(value.includes(-1) && selectedTeams.includes(-1)){
                        setSelectedTeams(value.filter(el => el !== -1))
                    }else if(value.includes(-1) && !selectedTeams.includes(-1)){
                        setSelectedTeams([-1])
                    }else{
                        setSelectedTeams(value.filter(el => el !== -1))
                    }
                }

            }else{
                if(name === "id_frequency"){
                    const frequency = frequencies.find(el => el.id === value)
                    if(frequency){
                        const nbCB = week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue],0)
                        const playlist = playlists.find(el => el.id === autoplanner.id_playlist)
                        if(playlist && playlist.nbCB < nbCB ){
                            setAutoplanner({...autoplanner, [name]:value, id_playlist:-1})
                        }else {
                            setAutoplanner({...autoplanner, [name]:value})
                        }
                    }

                }else{
                    setAutoplanner({...autoplanner, [name]:value})
                }
            }
        }

    }

    useEffect(() => {
        setSelectedTeams(autoPlannerData.auto_planner_teams.length > 0 ? autoPlannerData.auto_planner_teams.map(el => el.team.id) : [-1])
        setAutoplanner(autoPlannerData)
    }, [autoPlannerData]);


    return (
        <div className={"w-full h-full"}>
            <div className={"w-full h-full flex justify-center items-center"}>
                <Card data-planCB={'autoPlanner'} className={"bg-white-blue border border-contrast-blue h-full  w-full " + (autoplanner.active ? "" : "opacity-65")} >
                    <Card.Header className={"w-full text-center bg-contrast-blue text-white ebloom-very-large-text ebloom-text p-0"}>
                        <div className={"grid grid-cols-12"}>

                            <div className={"col-start-4 col-end-10 flex justify-center items-center"}>
                                <span>Auto-Planner</span>
                                {(isFree) &&
                                    <LockOutlinedIcon className={"ml-5"}/>
                                }
                            </div>

                            <div className={"col-start-10 col-end-13 flex justify-center items-center"}>
                                <WhiteBlueSwitch data-planCB={'switchAutoPlanner'}
                                                 disabled={false}
                                                 checked={autoplanner.active} name={"automActive"}
                                                 onChange={handleChangeActive}/>
                            </div>
                        </div>


                    </Card.Header>
                    {
                        isSingle ?
                            <Card.Body className={"w-full relative  h-140"}>
                                <Row md={(!isStarter && teams.length > 0) ? 3 : 2} className={"h-full"}>
                                    <Col className={"flex justify-center items-center relative"}>
                                        <FullContrastBlueTextField data-planCB={'changeFrequency'} name={"id_frequency"} onChange={handleChange} value={autoplanner.id_frequency} fullWidth={true} size={"small"} select={true} label={_.upperFirst(t("admin.frequency"))}>
                                            {
                                                frequencies.map((item, index) => (
                                                    <MenuItem key={index} value={item.id} disabled={playlists.find(el => el.id === autoplanner.id_playlist) && autoplanner.id_playlist !== 0  ? playlists.find(el => el.id === autoplanner.id_playlist).nbCB < week.reduce((previousValue, currentValue) => previousValue + item[currentValue],0) : false}>{item.title}</MenuItem>
                                                ))
                                            }
                                        </FullContrastBlueTextField>
                                        <div className={"absolute bottom-0 cursor-pointer"} onClick={() => {handleOpenFrequency(autoplanner.id_frequency)}}>
                                            <span className={"text-contrast-blue underline"}>View frequency</span>
                                        </div>
                                    </Col>
                                    <Col className={"flex justify-center items-center"} >
                                        <FullContrastBlueTextField data-planCB={'selectPlaylist'} onChange={handleChange} name={"id_playlist"}
                                                                   value={autoplanner.id_playlist} fullWidth={true} size={"small"}
                                                                   select={true} label={_.upperFirst(t("core.playlist.playlist"))}>
                                            <MenuItem key={-1} value={0}
                                                      className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.library"))}</MenuItem>
                                            {
                                                playlists.map((item, index) => (
                                                    <MenuItem key={index} value={item.id} disabled={item.id !== autoplanner.id_playlist && (usedPlaylists.includes(item.id) ||item.nbCB < week.reduce((previousValue, currentValue) => previousValue + autoplanner.frequency[currentValue],0))}>{item.name}</MenuItem>
                                                ))
                                            }
                                        </FullContrastBlueTextField>
                                    </Col>
                                    {
                                        (!isStarter && teams.length > 0) &&
                                        <Col className={"flex justify-center items-center"} >
                                            <FullContrastBlueTextField name={"teams"} onChange={handleChange} value={selectedTeams}  size={"small"}
                                                                       select={true} label={_.upperFirst(t("admin.teams"))}
                                                                       SelectProps={{
                                                                           multiple: true,
                                                                           renderValue : (selected) => (
                                                                               <div className={"truncate"}>
                                                                                   {
                                                                                       selected.length === 1 ?
                                                                                           selected[0] === -1 ? _.upperFirst(t("core.allCollaborators"))
                                                                                               : teams.find(el => el.id === selected[0]).name
                                                                                           : selected.length +" "+ _.lowerFirst(t("admin.teams"))

                                                                                   }
                                                                               </div>
                                                                           )
                                                                       }}
                                            >
                                                <MenuItem key={-1} value={-1}>{_.upperFirst(t("core.allCollaborators"))}</MenuItem>
                                                <Divider key={-3}/>

                                                {teams.map((item, index) => (
                                                    <MenuItem key={index} value={item.id}>
                                                        <div>{item.name}</div>
                                                    </MenuItem>

                                                ))}
                                            </FullContrastBlueTextField>
                                        </Col>
                                    }
                                </Row>

                            </Card.Body>
                            :
                            <Card.Body className={"w-full flex flex-col justify-between items-stretch"}>
                                <Row md={3} data-planCB={'changeFrequency'}>
                                    <Col md={6} className={"flex justify-start items-center"}>
                                        <span>Frequency :</span>
                                    </Col>
                                    <Col md={6} className={"text-center p-0"}>
                                        <span onClick={() => {handleOpenFrequency(autoplanner.id_frequency)}} className={"opacity-100 text-contrast-blue underline cursor-pointer"}>{autoPlannerData.frequency.title}</span>
                                    </Col>
                                </Row>
                                <Row md={3} data-planCB={'selectPlaylist'} >
                                    <Col md={6} className={"flex justify-start items-center"}>
                                        <span>Playlist :</span>
                                    </Col>
                                    <Col md={6} className={"text-center p-0"}>
                                        <span>{autoPlannerData.playlist.name}</span>
                                    </Col>
                                </Row>
                                {
                                    (!isStarter && teams.length > 0) &&
                                    <Row md={3}>
                                        <Col md={6} className={"flex justify-start items-center"}>
                                            <span>Audiences :</span>
                                        </Col>
                                        <Col md={6} className={"text-center p-0"}>
                                            {
                                                autoPlannerData.auto_planner_teams.length >= 0 && autoPlannerData.auto_planner_teams.length < 2 ?
                                                    <span
                                                        className={"text-contrast-blue font-semibold"}>{"#" + (autoPlannerData.auto_planner_teams.length > 0 ?autoPlannerData.auto_planner_teams[0].team.name : "All")}</span>
                                                    :
                                                    <EbloomTooltip text={autoPlannerData.auto_planner_teams.map(el => el.team.name).join('\n')}>
                                                <span
                                                    className={"text-contrast-blue font-semibold"}>{"#" + (autoPlannerData.auto_planner_teams.length + " " + _.lowerFirst(t("admin.teams")))}</span>
                                                    </EbloomTooltip>
                                            }
                                        </Col>
                                    </Row>
                                }
                                {
                                    !isSingle &&
                                    <Row className={"p-0 mt-5"}>
                                        <div className={" p-0 w-full flex justify-end items-center"}>
                                            {
                                                shouldShowDelete &&
                                                <DeleteModal color={"text-contrast-blue"}
                                                             handleDelete={() => handleDeleteAutoPlanner(autoplanner.id)}
                                                             noRequest={true}/>
                                            }
                                            <EbloomTooltip placement={"top"} text={_.upperFirst(t("core.edit"))}>
                                                <IconButton className={"text-contrast-blue"} onClick={toggleDrawer(true)}>
                                                    <EditIcon className={" text-contrast-blue size-20"}/>
                                                </IconButton>
                                            </EbloomTooltip>
                                        </div>
                                    </Row>
                                }
                            </Card.Body>
                    }
                </Card>
            </div>
            <SideForm toggleDrawer={toggleDrawer} sideFormOpen={sideFormOpen} buttonDisabled={autoplanner.id_playlist === -1 || autoplanner.id_frequency === -1 ||selectedTeams.length === 0} showButtons={true} handleSave={() => {
                handleSaveChanges()
            }} formTitle={"core.edit"}>
                <div className={" w-300  flex justify-center items-center flex-col gap-15"}>
                    <FullContrastPurpleTextField name={"id_frequency"} onChange={handleChange} value={autoplanner.id_frequency} fullWidth={true} size={"small"} select={true} label={_.upperFirst(t("admin.frequency"))}>
                        {
                            frequencies.map((item, index) => (
                                <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                            ))
                        }
                    </FullContrastPurpleTextField>

                    <FullContrastPurpleTextField onChange={handleChange} name={"id_playlist"}
                                                 value={autoplanner.id_playlist} fullWidth={true} size={"small"}
                                                 select={true} label={_.upperFirst(t("core.playlist.playlist"))}>
                        <MenuItem key={-2} value={-1} disabled
                                  className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.select"))}</MenuItem>
                        <MenuItem key={-1} value={0}
                                  className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.library"))}</MenuItem>

                        {
                            playlists.map((item, index) => (
                                <MenuItem key={index} value={item.id} disabled={item.id !== autoplanner.id_playlist && (usedPlaylists.includes(item.id) || item.nbCB < week.reduce((previousValue, currentValue) => previousValue + (!!frequencies.find(el => el.id === autoplanner.id_frequency) ? frequencies.find(el => el.id === autoplanner.id_frequency)[currentValue] : 0) ,0))}>{item.name}</MenuItem>
                            ))
                        }
                    </FullContrastPurpleTextField>

                    {
                        (!isStarter && teams.length > 0) &&
                        <FormControl variant={"outlined"} fullWidth style={{
                            '& label.Mui-focused': {
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: "white",
                                '&.Mui-focused fieldset': {
                                    borderColor: Colors.EBLOOM_CONTRAST_PURPLE,
                                },
                                '&:hover fieldset': {
                                    borderColor:Colors.EBLOOM_CONTRAST_PURPLE,
                                },

                            },
                            '& .MuiFormHelperText-root':{
                                color:Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiFormLabel-root":{
                                color:Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiOutlinedInput-root fieldset":{
                                borderColor: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiInputBase-input":{
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiSelect-iconOutlined":{
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor:  Colors.EBLOOM_CONTRAST_PURPLE,
                            },
                        }}>
                            <InputLabel className={"text-contrast-purple bg-bg-white"} id={"3"}>{_.upperFirst(t("admin.teams"))}</InputLabel>
                            <Select
                                className={"text-contrast-purple border border-contrast-purple"}
                                size="small"
                                id={"3"}
                                multiple
                                name={"teams"}
                                value={selectedTeams}
                                onChange={(event) => handleChange(event)}
                                renderValue={(selected) => (
                                    <div className={""}>
                                        {
                                            selected.length === 1 ?
                                                selected[0] === -1 ? _.upperFirst(t("core.allCollaborators"))
                                                    : teams.find(el => el.id === selected[0]).name
                                                : selected.length +" "+ _.lowerFirst(t("admin.teams"))

                                        }
                                    </div>
                                )}
                            >
                                <MenuItem key={-1} value={-1}>{_.upperFirst(t("core.allCollaborators"))}</MenuItem>
                                <Divider key={-3}/>

                                {teams.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        <div>{item.name}</div>
                                    </MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                    }
                </div>
            </SideForm>
            <Modal show={showModalStartPlanner} onHide={handleCloseModal} style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_LOGO_BLUE, color:"white", textAlign:"center"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.capitalize(t("admin.autoPlannerTitle"))}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}} className={"ebloom-normal-text"}>
                    <p>{_.upperFirst(t("admin.autoPlannerHelper"))}</p>
                    {
                        isFree &&
                        <div className={"text-line-break"}>
                            <p>{_.upperFirst(t("free.autoPlannerText"))}</p>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                    <Button variant={"secondary"} className={"ebloom-normal-text"} onClick={handleCloseModal} disabled={loadingPlannerNow || loadingPlannerNext}>{_.capitalize(t("core.cancel"))}</Button>
                    <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={startAutoPlanner("now")} disabled={loadingPlannerNow || loadingPlannerNext}>{loadingPlannerNow ? <LoadingView size={16}/> : _.upperCase(t("core.startNow"))}</Button>
                    <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={startAutoPlanner("nextWeek")} disabled={loadingPlannerNow || loadingPlannerNext}>{loadingPlannerNext ? <LoadingView size={16} /> : _.upperCase(t("core.startNext"))}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalFrequencyEmpty} onHide={() => setModalFrequencyEmpty(false)} style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_LOGO_BLUE, color:"white", textAlign:"center"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{"Empty frequency"}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}} className={"ebloom-normal-text"}>
                    <p>{_.upperFirst("Your frequency for this auto planner is empty. Modify it to attribute Click&Bloom and to use it.")}</p>
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                    <Button variant={"secondary"} className={"ebloom-normal-text"} onClick={() => setModalFrequencyEmpty(false)} >{_.capitalize(t("core.close"))}</Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal showButtons={false} size={"lg"} show={showFrequency} handleClose={handleCloseFrequency} title={_.upperFirst(t("admin.frequency")+ (!!frequency ? " : "+ frequency.title : ""))}>
                {
                    frequency &&
                    <div>
                        <Row className={"p-0 mb-15"}>
                            <Col md={2}>
                                <span>Recurrency :</span>
                            </Col>
                            <Col md={4} className={"text-start"}>
                                <span className={"text-contrast-blue font-semibold"}>
                                    {recurrencies.filter(el => el.value === frequency.recurrency).length > 0 ? _.upperFirst(t(recurrencies.filter(el => el.value === frequency.recurrency)[0].name)) : "Undefined"}
                                </span>
                            </Col>
                        </Row>
                        <Row md={5} style={{margin: "auto", marginBottom: 20}}>
                            {
                                week.map((day, index) => (
                                    <Col style={{padding: 2}} key={index}>
                                        <Card style={{minWidth: 120, borderColor: Colors.EBLOOM_CONTRAST_PURPLE}}>
                                            <Card.Header style={{textAlign: "center", backgroundColor:Colors.EBLOOM_CONTRAST_PURPLE, color:"white"}} className={"ebloom-text ebloom-large-text"}>{_.capitalize(t("core.day." + day))}</Card.Header>
                                            <Card.Body>
                                                <ListGroup variant={"flush"}>
                                                    {
                                                        Array(frequency[day]).fill("Click&Bloom").map((value, index) => (
                                                            <ListGroup.Item key={index} className={"flex justify-center items-center"} style={{paddingLeft:0, paddingRight:0}}>
                                                                <div style={{textAlign:"center"}}>
                                                                    <span className={"text-center"} style={{float:"left"}}>{value}</span>
                                                                </div>
                                                            </ListGroup.Item>
                                                        ))
                                                    }
                                                </ListGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>

                }
                {
                    frequencies &&
                    <Row>
                        <div className={"w-full flex justify-center items-center"}>
                            <span className={"text-center text-contrast-blue underline cursor-pointer"} onClick={() => {navigate("/admin/frequency/"+autoplanner.id_frequency)}}>
                                Modify this frequency
                            </span>
                        </div>
                    </Row>
                }
            </ConfirmModal>
            <Modal show={openStartModal} onHide={handleCloseModalStart} style={{borderColor: Colors.EBLOOM_LOGO_BLUE}}>
                <Modal.Header closeButton style={{backgroundColor:Colors.EBLOOM_LOGO_BLUE, color:"white", textAlign:"center"}}>
                    <Modal.Title className={"ebloom-xtra-large-text ebloom-text"}>{_.capitalize(t("admin.autoPlannerTitle"))}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}} className={"ebloom-normal-text"}>
                    <p>{_.upperFirst(t("admin.autoPlannerHelper"))}</p>
                    {
                        isFree &&
                        <div className={"text-line-break"}>
                            <p>{_.upperFirst(t("free.autoPlannerText"))}</p>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:Colors.EBLOOM_WHITE_BLUE}}>
                    <Button variant={"secondary"} className={"ebloom-normal-text"} onClick={handleCloseModalStart} disabled={loadingPlannerNow || loadingPlannerNext}>{_.capitalize(t("core.cancel"))}</Button>
                    <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={startAutoPlanner("now")} disabled={loadingPlannerNow || loadingPlannerNext}>{loadingPlannerNow ? <LoadingView size={16}/> : _.upperCase(t("core.startNow"))}</Button>
                    <Button className={"ebloom-btn-purple ebloom-normal-text"} onClick={startAutoPlanner("next")} disabled={loadingPlannerNow || loadingPlannerNext}>{loadingPlannerNext ? <LoadingView size={16} /> : _.upperCase(t("core.startNext"))}</Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal handleClose={()=> setAutoPlannerAccessDenied(false)} showButtons={false} show={autoPlannerAccessDenied} title={"Activation forbidden"}>
                        <div>
                            You cannot activate this auto-planner because it will exceed the maximum number of questions per week for your users.
                        </div>
            </ConfirmModal>
        </div>
        /* OLD VERSION
        <Card data-planCB={'autoPlanner'} className={"bg-white-blue border border-contrast-blue w-1/2 m-auto mb-20"} onClick={isFree ? handleOpenModalFreeTrial : () => {}}>
                            <Card.Header className={"text-center bg-contrast-blue text-white ebloom-very-large-text ebloom-text py-0"}><span>Auto-Planner</span>{(isFree) && <LockOutlinedIcon className={"ml-5"}/>}</Card.Header>
                            <Card.Body>
                                <div className={"flex justify-around"}>
                                    <FullContrastBlueSwitch data-planCB={'switchAutoPlanner'} disabled={selectedPlaylist === -1 || isFree} checked={activeAuto} name={"automActive"} onChange={handleChangeActive} />
                                    <FullContrastBlueTextField data-planCB={'selectPlaylist'} disabled={isFree} className={"ebloom-normal-text"} size={"small"} variant={"outlined"} name={"select-playlist"} defaultValue={-1} select value={selectedPlaylist} onChange={handleChangeAutoPlaylist}>
                                        <MenuItem key={-2} value={-1} disabled className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.select"))}</MenuItem>
                                        <MenuItem key={-1} value={0} className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.library"))}</MenuItem>
                                        {
                                            playlists.map((playlist, index) => (
                                                <MenuItem disabled={playlist.nbCB < totalFrequency} key={index} value={playlist.id} className={"ebloom-normal-text"}>{playlist.name}</MenuItem>
                                            ))
                                        }
                                    </FullContrastBlueTextField>
                                    <EbloomInfoTooltip text={_.upperFirst(t("admin.plannerHelper3"))}/>
                                </div>
                                <div data-planCB={'changeFrequency'} className={"text-center mt-10"}>
                                    <span className={"ebloom-link-blue"} onClick={isFree ? handleOpenModalFreeTrial : companyToEdit ? () => navigate("/backoffice/frequency") : () => navigate("/admin/frequency")}>{_.upperFirst(t("admin.plannerFrequencyHelper"))}</span>
                                    {
                                        isFree &&
                                        <LockOutlinedIcon fontSize={"small"} className={"text-contrast-blue"}/>
                                    }
                                </div>

                            </Card.Body>
                        </Card>
         */
    );
};

AutoPlanner.propTypes = {
    autoPlannerData: PropTypes.object.isRequired,
    companyToEdit: PropTypes.object,
    playlists: PropTypes.array.isRequired,
    frequencies: PropTypes.array.isRequired,
    teams: PropTypes.array.isRequired,
    selectedEntity: PropTypes.number,
    fetchAutoPlanners: PropTypes.func.isRequired,
    shouldShowDelete: PropTypes.bool,
    isStarter: PropTypes.bool.isRequired,
    isSingle: PropTypes.bool,
    handleToday: PropTypes.func.isRequired,
    fetchNumberFutureQuestions: PropTypes.func.isRequired,
    usedPlaylists: PropTypes.array.isRequired
};
AutoPlanner.defaultProps = {
    shouldShowDelete: false,
    companyToEdit: null,
    selectedEntity: null,
    isSingle: false
}

export default AutoPlanner;
