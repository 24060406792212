import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col, Row} from "react-bootstrap";
import {createSuccessfullyCreatedNotification, notifyError} from "../../../../../../Utils/Notification";
import HttpApi from "../../../../../../httpApi";
import AutoPlanner from "./AutoPlanner";
import SideForm from "../../../../../Core/Modules/Views-Tables-Lists/SideForm";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {FullContrastPurpleTextField, Modules} from "../../../../../../Utils/Global";
import _ from "lodash";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {Divider, FormControl} from "@mui/material";
import {Colors} from "../../../../../../Utils/Colors";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {useTranslation} from "react-multi-lang/lib";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoadingView from "../../../../../Core/Layouts/LoadingView";

const AutoPlannersList = (props) => {
    const {selectedEntity,playlists,teams,handleToday, fetchNumberFutureQuestions,isStarter} = props

    const [autoPlanners, setAutoPlanners] = useState([]);
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [frequencies, setFrequencies] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([-1]);
    const [selectedFrequency, setSelectedFrequency] = useState(-1);
    const [selectedPlaylist, setSelectedPlaylist] = useState(-1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [usedPlaylists, setUsedPlaylists] = useState([]);
    const [loading, setLoading] = useState(false);

    const t = useTranslation();

    const itemsPerSlide = 3;
    const week = ["monday","tuesday","wednesday","thursday","friday"];


    const nextSlide = () => {
        if (currentIndex < autoPlanners.length+1 - itemsPerSlide) {
            setCurrentIndex(currentIndex + itemsPerSlide);
        }
    };

    const getColOffset = () => {
        if(autoPlanners.length === 1) return 'col-start-2 col-end-2';
        return ''
    }

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - itemsPerSlide);
        }
    };



    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(!open){
            setSelectedTeams([-1])
            setSelectedFrequency(-1)
            setSelectedPlaylist(-1)
        }
        setSideFormOpen(open);
    }

    const handleAddAutoPlanner = () => {
        const data = {
            id_playlist : selectedPlaylist,
            id_frequency : selectedFrequency,
            active : false,
            auto_planner_teams : selectedTeams.length === 1 && selectedTeams[0] === -1 ? [] : selectedTeams
        }
        let url = "/frequencies/autoPlanner/create"
        if(!!selectedEntity){
            url = "/frequencies/autoPlanner/create/"+selectedEntity
        }
        HttpApi.postV2(url,data).then((response) => {
            if(response && response.data){
                createSuccessfullyCreatedNotification("auto planner");
                setSideFormOpen(false)
                setSelectedTeams([-1])
                setSelectedFrequency(-1)
                setSelectedPlaylist(-1)
                fetchAutoPlanners();
            }
        })
    }

    const handleChange = (event) => {
        let value = event.target.value
        let name = event.target.name
        if (name === "teams") {
            if (value.length > 0) {
                if (value.includes(-1) && selectedTeams.includes(-1)) {
                    setSelectedTeams(value.filter(el => el !== -1))
                } else if (value.includes(-1) && !selectedTeams.includes(-1)) {
                    setSelectedTeams([-1])
                } else {
                    setSelectedTeams(value.filter(el => el !== -1))
                }
            }
        } else if (name === "id_playlist") {
            setSelectedPlaylist(value)
        } else if (name === "id_frequency") {
            const frequency = frequencies.find(el => el.id === value)
            if(frequency) {
                if(selectedPlaylist !== -1){
                    const nbCB = week.reduce((previousValue, currentValue) => previousValue + frequency[currentValue],0)
                    const playlist = playlists.find(el => el.id === selectedPlaylist)
                    if(playlist && playlist.nbCB < nbCB ){
                        setSelectedPlaylist(-1)
                    }
                }
                setSelectedFrequency(value)
            }
        }
    }


    const fetchAutoPlanners = () => {
        setLoading(true)
        let url = "/frequencies/autoPlanner/getAll"
        if(!!selectedEntity){
            url += "/"+selectedEntity
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data && response.data.length > 0){
                let usedPlaylistsId = []
                for (let i = 0; i < response.data.length; i++) {
                    if(response.data[i].id_playlist !== -1){
                        if(!usedPlaylistsId.includes(response.data[i].id_playlist)){
                            usedPlaylistsId.push(response.data[i].id_playlist)
                        }
                    }
                }
                setUsedPlaylists(usedPlaylistsId)
                setAutoPlanners(response.data);
            }
        }).catch(error => {
            notifyError(error);
        }).finally(() => {
            setLoading(false)
        })
    }

    const fetchFrequencies = () => {
        let url = "/frequencies/getAll"
        if(!!selectedEntity){
            url += "/"+selectedEntity
        }
        HttpApi.getV2(url).then(response => {
            if(response && response.data){
                setFrequencies(response.data);
            }
        }).catch(error => {
            notifyError(error);
        })
    }



    useEffect(() => {
        fetchAutoPlanners()
        fetchFrequencies()
    }, [selectedEntity]);

    return (
        loading ? <LoadingView/> :
        <div className={"w-full relative mb-40"}>
            {
                autoPlanners.length === 1 ?
                    <div className={"w-full flex justify-center items-center mb-20"}>
                        <div className={"w-1/2"}>
                            <AutoPlanner autoPlannerData={autoPlanners[0]} key={0}
                                         playlists={playlists}
                                         usedPlaylists={usedPlaylists}
                                         frequencies={frequencies}
                                         teams={teams}
                                         isSingle={true}
                                         isStarter={isStarter}
                                         shouldShowDelete={autoPlanners.length > 1}
                                         fetchAutoPlanners={fetchAutoPlanners}
                                         handleToday={handleToday}
                                         selectedEntity={selectedEntity}
                                         fetchNumberFutureQuestions={fetchNumberFutureQuestions}
                            />
                        </div>
                        <Button
                            className={"absolute right-0 h-full text-white ebloom-btn-purple flex flex-col justify-center items-center"}
                            onClick={toggleDrawer(true)}>
                            <span>Add an auto-planner</span>
                            <AddCircleOutlineIcon className={"mt-5 text-white size-30"}/>
                        </Button>
                    </div>

                    :
                    <div>
                        <Row className={"mb-20"}>
                            <Col md={1} className={"p-0 flex justify-center items-center"}>
                                {
                                    currentIndex > 0 &&
                                    <div className={"flex justify-center items-center"} onClick={prevSlide}>
                                        <KeyboardArrowLeftIcon className={"pointer text-purple"}/>
                                    </div>
                                }

                            </Col>
                            <Col md={10} className={"p-0 relative overflow-hidden"}>
                                <div
                                    className={`grid grid-cols-3 gap-x-10 transition-transform duration-300`}>
                                {
                                        autoPlanners.sort((a, b) => a.id - b.id).slice(currentIndex, (currentIndex + itemsPerSlide)).map((autoPlanner, index) => (
                                            <div
                                                className={`w-full ${getColOffset()}  flex flex-grow justify-center items-center`} key={index}>
                                                <AutoPlanner autoPlannerData={autoPlanner} key={index}
                                                             playlists={playlists}
                                                             usedPlaylists={usedPlaylists}
                                                             frequencies={frequencies}
                                                             teams={teams}
                                                             isSingle={false}
                                                             isStarter={isStarter}
                                                             shouldShowDelete={autoPlanners.length > 1}
                                                             fetchAutoPlanners={fetchAutoPlanners}
                                                             handleToday={handleToday}
                                                             selectedEntity={selectedEntity}
                                                             fetchNumberFutureQuestions={fetchNumberFutureQuestions}
                                                />

                                            </div>
                                        ))
                                    }
                                    {
                                        autoPlanners.sort((a, b) => a.id - b.id).slice(currentIndex, (currentIndex + itemsPerSlide)).length < 3 &&
                                        <div
                                            className={" min-h-185 w-full  flex justify-center items-center "}>
                                            <div className={"w-full h-full flex justify-center items-center "}>
                                                <Button
                                                    className={" h-full text-white ebloom-btn-purple flex flex-col justify-center items-center"}
                                                    onClick={toggleDrawer(true)}>
                                                    <span>Add an auto-planner</span>
                                                    <AddCircleOutlineIcon className={"mt-5 text-white size-30"}/>
                                                </Button>
                                            </div>
                                        </div>

                                    }

                                </div>
                            </Col>
                            <Col md={1} className="p-0 flex justify-center items-center">
                                {
                                    currentIndex < autoPlanners.length + 1 - itemsPerSlide &&
                                    <div onClick={nextSlide}>
                                        <KeyboardArrowRightIcon className="pointer text-purple"/>
                                    </div>
                                }

                            </Col>
                        </Row>
                        {
                            Math.ceil((autoPlanners.length + 1) / itemsPerSlide) > 1 &&
                            <div className={"p-0 w-full flex justify-center items-center relative flex-wrap"}>
                                {
                                    Array(Math.ceil((autoPlanners.length + 1) / itemsPerSlide)).fill(0).map((value, index) => (
                                        <div className={"size-15 mx-15 flex justify-center items-center"} key={index}>
                                            <div
                                                className={Math.ceil(currentIndex / 3) === index ? "size-15 rounded-full  bg-contrast-blue ease-in duration-100 " : "size-6 rounded-full  bg-contrast-blue ease-in duration-100"}>
                                            </div>
                                        </div>

                                    ))
                                }
                            </div>
                        }
                    </div>
            }


            <SideForm toggleDrawer={toggleDrawer} sideFormOpen={sideFormOpen}
                      buttonDisabled={selectedPlaylist === -1 || selectedFrequency === -1 || selectedTeams.length === 0}
                      showButtons={true} handleSave={() => {
                handleAddAutoPlanner()
            }} formTitle={"core.add"}>
                <div className={" w-300  flex justify-center items-center flex-col gap-15"}>
                    <FullContrastPurpleTextField name={"id_frequency"} onChange={handleChange}
                                                 value={selectedFrequency} fullWidth={true} size={"small"}
                                                 select={true} label={_.upperFirst(t("admin.frequency"))}>
                        <MenuItem key={-1} value={-1} disabled
                                  className={"ebloom-normal-text"}>{_.upperFirst(t("admin.autoPlanner.selectFrequency"))}</MenuItem>
                        {
                            frequencies.map((item, index) => (
                                <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                            ))
                        }
                    </FullContrastPurpleTextField>
                    <FullContrastPurpleTextField disabled={selectedFrequency === -1} onChange={handleChange} name={"id_playlist"}
                                                 value={selectedPlaylist} fullWidth={true} size={"small"}
                                                 select={true} label={_.upperFirst(t("core.playlist.playlist"))}>
                        <MenuItem key={-2} value={-1} disabled
                                  className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.select"))}</MenuItem>
                        <MenuItem key={-1} value={0}
                                  className={"ebloom-normal-text"}>{_.upperFirst(t("core.playlist.library"))}</MenuItem>
                        {
                            playlists.map((item, index) => (
                                <MenuItem key={index} value={item.id} disabled={usedPlaylists.includes(item.id) || (frequencies.filter(el => el.id === selectedFrequency).length > 0 ? item.nbCB < week.reduce((previousValue, currentValue) => previousValue + frequencies.find(el => el.id === selectedFrequency)[currentValue],0) : true)}>{item.name}</MenuItem>
                            ))
                        }
                    </FullContrastPurpleTextField>

                    {
                        (!isStarter && teams.length > 0) &&
                        <FormControl variant={"outlined"} fullWidth style={{
                            '& label.Mui-focused': {
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: "white",
                                '&.Mui-focused fieldset': {
                                    borderColor: Colors.EBLOOM_CONTRAST_PURPLE,
                                },
                                '&:hover fieldset': {
                                    borderColor: Colors.EBLOOM_CONTRAST_PURPLE,
                                },

                            },
                            '& .MuiFormHelperText-root': {
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiFormLabel-root": {
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiOutlinedInput-root fieldset": {
                                borderColor: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiInputBase-input": {
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            "& .MuiSelect-iconOutlined": {
                                color: Colors.EBLOOM_CONTRAST_PURPLE
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: Colors.EBLOOM_CONTRAST_PURPLE,
                            },
                        }}>
                            <InputLabel className={"text-contrast-purple bg-bg-white"}
                                        id={"3"}>{_.upperFirst(t("admin.teams"))}</InputLabel>
                            <Select
                                className={"text-contrast-purple border border-contrast-purple"}
                                size="small"
                                id={"3"}
                                multiple
                                name={"teams"}
                                value={selectedTeams}
                                onChange={(event) => handleChange(event)}
                                renderValue={(selected) => (
                                    <div className={""}>
                                        {
                                            selected.length === 1 ?
                                                selected[0] === -1 ? _.upperFirst(t("core.allCollaborators"))
                                                    : teams.find(el => el.id === selected[0]).name
                                                : selected.length + " " + _.lowerFirst(t("admin.teams"))

                                        }
                                    </div>
                                )}
                            >
                                <MenuItem key={-1} value={-1}>{_.upperFirst(t("core.allCollaborators"))}</MenuItem>
                                <Divider key={-3}/>

                                {teams.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        <div>{item.name}</div>
                                    </MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                    }
                </div>
            </SideForm>
        </div>

    );
};

AutoPlannersList.propTypes = {
    isStarter : PropTypes.bool.isRequired,
    selectedEntity: PropTypes.number,
    teams: PropTypes.array.isRequired,
    playlists: PropTypes.array.isRequired,
    handleToday: PropTypes.func.isRequired,
    fetchNumberFutureQuestions: PropTypes.func.isRequired
};
AutoPlannersList.defaultProps = {
    selectedEntity: null
}

export default AutoPlannersList;
