import React, {useEffect} from 'react';

//CSS STYLES

import "./css/custom.css";

import "./Components/Core/Style/Ebloom.css";
import "./Components/Core/Style/EbloomGraph.css";

import {createTheme, ThemeProvider} from "@mui/material/styles";
//NOTIFICATIONS
import {ReactNotifications} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';

//ROUTER
import EbloomRouter from "./routes/EbloomRouter";

//TRANSLATIONS
import { setTranslations, setDefaultLanguage } from 'react-multi-lang'
import en from "./lang/dictionary/en";
import fr from "./lang/dictionary/fr";
import nl from "./lang/dictionary/nl";

import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";

//UTILS
import { Crisp } from "crisp-sdk-web";
import HttpApi from "./httpApi";
//STORE
import { useDispatch } from 'react-redux'
import {
    updateId,
    updateEmail,
    updateName,
    updateCompany,
    updateIdCompany,
    updateRoles,
    updateModule, updateCompanyType
} from "./store/user";

//PROPS

//TRACKING
import {NavigationModal} from "./hooks/navigationHook";
import {ProviderProductTour} from "./Utils/Context/ProductTourContext";
import {ProviderGlobal} from "./Utils/Context/GlobalContext";
import {useBreakpoints} from "./hooks/breakpointsHook";
import {Colors} from "./Utils/Colors";
import {notifyError} from "./Utils/Notification";
import {CompanyTypes} from "./Utils/Global";
import {getFromStorage} from "./Utils/Storage";

Crisp.configure("13cc034e-45da-4b7a-90ac-a201ded30a77");

setTranslations({en, fr, nl});
setDefaultLanguage('en');

moment.locale("en");

//Root url

//HttpApi.setBaseUrl(process.env.REACT_APP_API_URL);
//HttpApi.setBaseUrl("http://localhost:8080/api");
HttpApi.setBaseUrl("https://www.ebloom-testnbloom.be/api");
//HttpApi.setBaseUrl("https://www.ebloom-clicknbloom.com/api");
let url = new URL(window.location.href);
let tokenUrl = url.searchParams.get("key");
if(tokenUrl){
    HttpApi.connectPublicApp(tokenUrl)
}else{
   await HttpApi.connectApp();
}


const App = (props) => {

    const dispatch = useDispatch();
    const bp = useBreakpoints();

    useEffect(() => {
        let url = new URL(window.location.href);
        let tokenUrl = url.searchParams.get("key");
        if(tokenUrl){ //for public connection
            HttpApi.getPublicCredentials(tokenUrl).then(response => {
                if(response && response.id){
                    dispatch(updateId(response.id))
                    dispatch(updateEmail(response.email)) // should be null
                    dispatch(updateName(response.name)) //should be null
                    dispatch(updateCompany(response.company))
                    dispatch(updateIdCompany(response.id_company))
                    dispatch(updateRoles(response.roles))
                    dispatch(updateModule(response.module))
                    dispatch(updateCompanyType(CompanyTypes.DATATOPIA))
                }
            }).catch(error => {
                notifyError(error);
            })
        }else {
            dispatch(updateId(getFromStorage("id")));
            dispatch(updateEmail(getFromStorage("email")));
            dispatch(updateName(getFromStorage("name")));
            dispatch(updateCompany(getFromStorage("company")));
            dispatch(updateIdCompany(getFromStorage("id_company")));
            dispatch(updateRoles(getFromStorage("roles")));
            dispatch(updateModule(getFromStorage("module")));
            dispatch(updateCompanyType(getFromStorage("company_type")));
        }
    }, [])

    const theme = createTheme({
        palette:{
            primary:{main:Colors.EBLOOM_PURPLE},
            secondary:{main:Colors.EBLOOM_CONTRAST_PURPLE},
        }
    });

    return (
        <div className="App">
            <ReactNotifications/>
            <NavigationModal/>
            <ThemeProvider theme={theme}>
                <ProviderProductTour>
                    <ProviderGlobal>
                        <EbloomRouter isMobile={bp.isMobile()} isTablet={bp.isTablet()} isLandscapeTablet={bp.isTabletLandscape()} bp={bp} {...props}/>
                    </ProviderGlobal>
                </ProviderProductTour>
            </ThemeProvider>
        </div>

    );

}

export default App;