import React from 'react';
import {createRoot} from "react-dom/client";
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from "./serviceWorker";
import {Provider} from "react-redux";
import store from "./store/store";


const root=createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename="/">
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);

serviceWorker.unregister();
